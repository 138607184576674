import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import logoRoueBioCentre from '../Assets/logos/logo-roue-biodiversite-centre.png';

export default function Header() {
  const router = useHistory();
  const classes = useStyles();

  return (
    <Box >
      <Grid className={classes.header}>
        <img onClick={() => { router.push(`/home`) }} className={classes.logo}
          src={logoRoueBioCentre} alt="logo roue biodiversite" />
        <Button className={classes.btn} onClick={() => { router.push(`/home`) }} >Accueil</Button>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    padding: "20px 30px 20px 30px",
    display: "flex",
    justifyContent: "space-between",
    maxHeight: 66,
    [theme.breakpoints.up('sm')]: {
      padding: "30px 150px 30px 150px"
    },
  },
  logo: {
    maxWidth: "40%",
    [theme.breakpoints.up('md')]: {
      maxWidth: 190
    },
  },
  btn: {
    background: "transparent linear-gradient(180deg, #78B41D 0%, #59831B 100%) 0% 0% no-repeat padding-box",
    width: 123,
    height: 34,
    border: "1px white solid",
    padding: 0,
    marginTop: 4
  }
}));