import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, Grid } from '@material-ui/core';
import { Lil } from '@loginline/odoo-frontend'

function httpHtml(content) {
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  return content.replace(reg, "<a target='_blank' href='$1$2'>$1$2</a>");
}

export default function Answer(props) {

  const [store,] = Lil.useStore();
  const [moreInfo, setMoreInfo] = useState(false);
  const classes = useStyles();

  return (
    <Box className={classes.container}>

      {moreInfo ? (
        <>
          <Grid className={classes.answerContainer}>
            <Typography className={classes.answer} dangerouslySetInnerHTML={{
              __html: httpHtml(store.question.more).replace(/\n/g, '<br />')
            }} />
          </Grid>
          <Button className={classes.moreBtn} onClick={() => setMoreInfo(!moreInfo)}>
            Réduire
          </Button>
        </>
      ) : (
          <>
            <Grid className={classes.answerContainer}>
              <Typography className={classes.answer}>
                {store.question.answerPhrase}
              </Typography>
            </Grid>
            <Button className={classes.moreBtn} onClick={() => setMoreInfo(!moreInfo)}>
              En savoir plus
            </Button>
          </>
        )
      }

      <Box className={classes.buttonsContainer}>
        <Button className={classes.nextQBtn} onClick={() => { props.changeVue("question") }}  >
          <Typography variant="h5">
            Question suivante
        </Typography>
        </Button>

        <Button className={classes.stopBtn} onClick={() => { props.changeVue("score") }}>
          <Typography variant="h5">
            Je m'arrête là
        </Typography>
        </Button>

        <Button className={classes.nextQBtn} onClick={() => { props.changeVue("wheel") }}>
          <Typography variant="h5">
            Relancer la roue
        </Typography>
        </Button>
      </Box>

    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 175px)',
    },
  },
  answerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  answer: {
    fontWeight: "lighter",
    width: "90%",
    [theme.breakpoints.up('xs')]: {
      fontSize: 24
    },
    [theme.breakpoints.up('md')]: {
      width: "60%",
    },
  },
  moreBtn: {
    fontSize: 14,
    height: 35,
    marginTop: '5%',
    padding: "0 10px 0 10px",
    background: " transparent linear-gradient(180deg, #2AA7BC 0%, #14606E 100%) 0% 0% no-repeat padding-box",
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginTop: 10
    }
  },
  nextQBtn: {
    width: 260,
    height: 64,
    marginTop: 20,
    background: "transparent linear-gradient(180deg, #FFA81C 0%, #E87400 100%) 0% 0% no-repeat padding-box",
    [theme.breakpoints.up('sm')]: {
      margin: 10,
    }
  },
  stopBtn: {
    width: 260,
    height: 64,
    marginTop: 20,
    background: "transparent linear-gradient(180deg, #2AA7BC 0%, #14606E 100%) 0% 0% no-repeat padding-box",
    [theme.breakpoints.up('sm')]: {
      margin: 10
    },
  }
}));