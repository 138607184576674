import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Grid, Slide } from '@material-ui/core';
import { Lil } from '@loginline/odoo-frontend'
import { useHistory } from 'react-router-dom';
import { motion } from "framer-motion"
import dataThemes from '../dataThemes.json';
import Wheel from "../Components/Wheel";
import WindowTheme from "../Components/WindowTheme";
import Fade from '@material-ui/core/Fade';

export default function WheelPlay(props) {
  const router = useHistory();
  const classes = useStyles(props.dataThemes);

  const [, update] = Lil.useStore();
  const [wheelQuestion, setWheelQuestion] = useState(-1);
  const [transition, setTransition] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({ name: (props.prevTheme ? props.prevTheme.name : "À taille microscopique"), color: props.prevTheme ? props.prevTheme.color : "ataillemicro", bgUrl: props.prevTheme ? props.prevTheme.bgURL : "microscopique.png" });
  const changeVue = () => {
    setTimeout(function () { router.push(`/app/tree`) }, 4000);
  }

  const changeTheme = (theme) => {
    setTimeout(() => {
      setCurrentCategory(theme);
      update({ category: theme.name }); //This Line is here for HeaderQuiiz be synchronized with wheelPlay on display theme
    }, 2000);
  }

  return (
    <Box className={classes.wheelplayContainer}>

      <Grid className={classes.gridWindow}>
        <WindowTheme themeProps={currentCategory} key={dataThemes[props.prevTheme ? props.prevTheme.id : 0].name} />
      </Grid>

      <Grid className={classes.gridWheel}
        onClick={() => {
          if (!disabled) {
            setTransition(false);
            const random = Math.floor(Math.random() * 10)
            setWheelQuestion(random);
            changeVue();
            changeTheme(dataThemes[random]);
            setDisabled(true);
          }
        }} >
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1, transition: { duration: 0.5 } }}
        >
          <Wheel className={classes.gridWheel} categoryIndex={wheelQuestion} initialIndex={props.prevTheme ? props.prevTheme.id : 0} />
        </motion.div>
      </Grid>

      <Grid className={classes.gridBtn}>
        <Slide direction="left" in={true} mountOnEnter>
          <Fade in={transition} timeout={{ exit: 3000 }} >
            <Button className={classes.launchWheelButton} disabled={disabled} onClick={() => {
              setDisabled(true);
              setTransition(false);
              const random = Math.floor(Math.random() * 10)
              setWheelQuestion(random);
              changeVue();
              changeTheme(dataThemes[random]);
            }}>
              <Typography variant="h5">Lancer</Typography>
            </Button>
          </Fade>
        </Slide>
      </Grid>

    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  wheelplayContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 175px)',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  gridWheel: {
    [theme.breakpoints.up('md')]: {
      order: 0,
    },
  },
  gridWindow: {
    [theme.breakpoints.up('md')]: {
      order: 1,
      marginBottom: 200,
      marginLeft: 80
    },
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
  gridBtn: {
    [theme.breakpoints.up('md')]: {
      order: 1,
      marginTop: 100,
      marginLeft: -255
    },
    [theme.breakpoints.up('lg')]: {
      order: 1,
      marginTop: 80,
      marginLeft: 0
    },
  },
  launchWheelButton: {
    background: 'linear-gradient(180deg, #FFA81C 0%, #E87400 100%)',
    width: 155,
    height: 45,
    margin: 22,
    border: "none",
    [theme.breakpoints.up('lg')]: {
      width: 180,
      height: 53,
      order: 3,
      marginLeft: -310,
      marginTop: 100
    },
  }
}));