import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import txtHat from '../Assets/textures/chapeau.png';
import txtShells from '../Assets/textures/coquilles.png';
import txtScales from '../Assets/textures/ecailles.png';
import txtMicro from '../Assets/textures/microscopique.png';
import txtSkin from '../Assets/textures/peau-lisse.png';
import txtPaws from '../Assets/textures/pattes.png';
import txtFeathers from '../Assets/textures/plumes.png';
import txtHair from '../Assets/textures/poils.png';
import txtRoots from '../Assets/textures/racines.png';
import txtKnow from '../Assets/textures/savoir.png';

const textures = {
  'chapeau.png': txtHat,
  'coquilles.png': txtShells,
  'ecailles.png': txtScales,
  'microscopique.png': txtMicro,
  'peau-lisse.png': txtSkin,
  'pattes.png': txtPaws,
  'plumes.png': txtFeathers,
  'poils.png': txtHair,
  'racines.png': txtRoots,
  'savoir.png': txtKnow,
}

export default function WindowTheme(props) {
  const classes = useStyles(props.themeProps);

  return (
    <Box className={classes.thematicSelected}>
      <Typography variant="h4">{props.themeProps.name}</Typography>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  thematicSelected: props => ({
    width: 312,
    height: 50,
    margin: "10px 0",
    backgroundColor: theme.palette[props.color].main,
    backgroundImage: `url(${textures[props.bgUrl]})`,
    // backgroundColor: '#282d83',
    border: 'solid 3px white',
    borderRadius: 20,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: 450,
      height: 80,
      order: 2,
      alignSelf: 'flex-start',
      marginTop: 120,
      marginLeft: 80
    },
  }),
}));