import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Components
import Header from './Components/Header';
import Footer from './Components/Footer';

//Pages
import Home from './Pages/Home';
import WheelPlay from "./Pages/WheelPlay";
import Tree from "./Pages/Tree";
import Thematics from "./Pages/Thematics";
import Biodiversity from "./Pages/Biodiversity";
import Legals from "./Pages/Legals";
import Quiz from "./Pages/Quiz";
import bgCloud1920 from "./Assets/fond-nuages/fond-nuages-1920-2.png";
import bgCloud600 from "./Assets/fond-nuages/fond-nuages-600.png";
import bgCloud960 from "./Assets/fond-nuages/fond-nuages-960.png";
import bgCloud1280 from "./Assets/fond-nuages/fond-nuages-1280.png";

function App() {
  const classes = useStyles();
  return (
    <Router>
      <div className={classes.app}>

        <Route path='/app' component={Header} />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/home' component={Home} />
          <Route path='/app/wheelplay' component={WheelPlay} />
          <Route path='/app/tree' component={Tree} />
          <Route path='/app/thematics' component={Thematics} />
          <Route path='/biodiversity' component={Biodiversity} />
          <Route path='/legals' component={Legals} />
          <Route path='/quiz' component={Quiz} />
        </Switch>
        <Route path='/app' component={Footer} />

      </div>
    </Router>

  );
}

const useStyles = makeStyles(theme => ({
  app: {
    minHeight: '100vh',
    backgroundImage: `url(${bgCloud600})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${bgCloud960})`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${bgCloud1280})`,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${bgCloud1920})`,
    }
  },
}));

export default App;


