import React from 'react';
import { Button, Box, Grid, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { motion } from "framer-motion";
import { Lil } from '@loginline/odoo-frontend'
import FooterHome from '../Components/FooterHome';
import RoueBiodiversite from '../Assets/roue_biodiversite.png';
import DoubleArrow from '../Assets/icones/double-arrow.png';
import logoRoueBioCentre from '../Assets/logos/logo-roue-biodiversite-centre.png';

const variants = {
  visible: i => ({
    opacity: 1,
    x: -5,
    transition: {
      delay: i * 0.1,
    },
  }),
};

export default function Home() {
  const classes = useStyles();
  const router = useHistory();
  const [, update] = Lil.useStore();

  return (
    <Box>
      <Slide direction="up" in={true} mountOnEnter>
        <Box className={classes.header}>
          <img className={classes.logoHeader} src={logoRoueBioCentre} alt="logo roue biodiversite" />
        </Box>
      </Slide>

      <Box className={classes.wrapHome}>
        <Grid>
          <img className={classes.wheel} src={RoueBiodiversite} alt="biodiversity wheel" />
        </Grid>

        <Grid>
          <motion.div
            custom={1}
            initial={{ opacity: 0, x: -25 }}
            animate="visible"
            variants={variants}
          >
            <Button className={classes.homeBtn1} onClick={() => { router.push(`/app/wheelplay`); update({ counter: [] }) }}>
              <Grid item xs={12} md={12} className={classes.gridTextBtn}>
                <Grid item xs={12} md={12}>
                  <Box className={classes.titleBtn}>Lancez la roue</Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className={classes.textBtn}>Répondez à des questions au hasard sur des thèmes de la biodiversité</Box>
                </Grid>
              </Grid>
              <Grid className={classes.chevronBox}>
                <img className={classes.chevron} src={DoubleArrow} alt="double arrow right" />
              </Grid>
            </Button>
          </motion.div>
        </Grid>
        <Grid>
          <motion.div
            custom={2}
            initial={{ opacity: 0, x: -25 }}
            animate="visible"
            variants={variants}
          >
            <Button className={classes.homeBtn2} onClick={() => { router.push(`/app/thematics`) }}>
              <Grid item xs={12} md={12} className={classes.gridTextBtn}>
                <Grid item xs={12} md={12}>
                  <Box className={classes.titleBtn}>Choisir un thème</Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className={classes.textBtn}>Définissez directement avec quel thème vous souhaitez jouer</Box>
                </Grid>
              </Grid>
              <Grid className={classes.chevronBox}>
                <img className={classes.chevron} src={DoubleArrow} alt="double arrow right" />
              </Grid>
            </Button>
          </motion.div>
        </Grid>
        <Grid>
          <motion.div
            custom={3}
            initial={{ opacity: 0, x: -25 }}
            animate="visible"
            variants={variants}
          >
            <Button className={classes.homeBtn3} onClick={() => { router.push(`/biodiversity`) }}>
              <Grid item xs={12} md={12} className={classes.gridTextBtn}>
                <Grid item xs={12} md={12}>
                  <Box className={classes.titleBtn}>La biodiversité chez vous</Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className={classes.textBtn}>Découvrez des actions pour protéger la biodiversité dans votre quotidien</Box>
                </Grid>
              </Grid>
              <Grid className={classes.chevronBox}>
                <img className={classes.chevron} src={DoubleArrow} alt="double arrow right" />
              </Grid>
            </Button>
          </motion.div>
        </Grid>
      </Box>
      <FooterHome />
    </Box >
  )
}

const useStyles = makeStyles(theme => ({
  //CSS header
  header: {
    textAlign: 'center',
    padding: "20px 0 20px 0",
  },
  logoHeader: {
    width: "50%",
    [theme.breakpoints.up('sm')]: {
      width: "40%"
    },
    [theme.breakpoints.up('md')]: {
      width: "18%",
      marginTop: 20
    },
  },
  // CSS home
  wrapHome: {
    textAlign: 'center',
  },
  wheel: {
    float: "left",
    position: 'relative',
    zIndex: 1,
    maxHeight: 140,
    marginLeft: "-70px",
    [theme.breakpoints.up('sm')]: {
      maxHeight: 600,
      marginLeft: "-300px",
      marginTop: 60
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 350,
      marginLeft: "20%",
      marginTop: 0
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: 450,
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: 550,
    },
  },
  homeBtn1: {
    margin: "10px 0 10px 0",
    width: "90%",
    height: 90,
    background: "transparent linear-gradient(180deg, #FFA81C 0%, #E87400 100%) 0% 0% no-repeat padding-box",
    textAlign: 'left',
    padding: "5px 20px 0 60px",
    marginTop: "-150px",
    border: "none",
    [theme.breakpoints.up('sm')]: {
      height: 150,
      borderRadius: "65px",
      marginTop: "-950px"
    },
    [theme.breakpoints.up('md')]: {
      height: 80,
      marginTop: "20px",
      maxWidth: 600,
      marginLeft: "-40%",
    },
    [theme.breakpoints.up('lg')]: {
      height: 110,
      borderRadius: "40px",
    },
    [theme.breakpoints.up('xl')]: {
      height: 145,
      maxWidth: 700,
      borderRadius: "55px",
    },
  },
  homeBtn2: {
    margin: "-20px 0 10px 0",
    width: "90%",
    height: 90,
    background: "transparent linear-gradient(180deg, #2AA7BC 0%, #14606E 100%) 0% 0% no-repeat padding-box",
    textAlign: 'left',
    padding: "5px 20px 0 60px",
    border: "none",
    [theme.breakpoints.up('sm')]: {
      height: 150,
      borderRadius: "65px",
      marginTop: "-650px"
    },
    [theme.breakpoints.up('md')]: {
      height: 80,
      maxWidth: 600,
      marginTop: "20px",
      marginLeft: "-40%",
    },
    [theme.breakpoints.up('lg')]: {
      height: 110,
      borderRadius: "40px",
    },
    [theme.breakpoints.up('xl')]: {
      height: 145,
      maxWidth: 700,
      borderRadius: "55px",
    },
  },
  homeBtn3: {
    margin: "10px 0 10px 0",
    width: "90%",
    height: 90,
    background: "transparent linear-gradient(180deg, #78B41D 0%, #59831B 100%) 0% 0% no-repeat padding-box",
    textAlign: 'left',
    padding: "5px 20px 0 60px",
    border: "none",
    [theme.breakpoints.up('sm')]: {
      height: 150,
      borderRadius: "65px",
      marginTop: "-350px"
    },
    [theme.breakpoints.up('md')]: {
      height: 80,
      maxWidth: 600,
      marginTop: "20px",
      marginLeft: "-40%",
    },
    [theme.breakpoints.up('lg')]: {
      height: 110,
      borderRadius: "40px",
    },
    [theme.breakpoints.up('xl')]: {
      height: 145,
      maxWidth: 700,
      borderRadius: "55px",
    },
  },
  chevronBox: {
    width: 40,
    textAlign: "center",
  },
  chevron: {
    opacity: "0.3",
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  gridTextBtn: {
    maxHeight: 90,
    paddingRight: 5,
    marginTop: -15,
    [theme.breakpoints.up('sm')]: {
      marginTop: -5,
    },
  },
  titleBtn: {
    lineHeight: "15px",
    [theme.breakpoints.up('sm')]: {
      lineHeight: "30px",
      marginTop: "-15px",
      fontSize: "1.5em",
      paddingLeft: 220,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: "-5px",
      fontSize: "1.2em",
      paddingLeft: "40%",
      marginBottom: -5
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: 10,
      fontSize: "26px",
    },
  },
  textBtn: {
    fontSize: "0.8em",
    fontWeight: "lighter",
    opacity: "0.8",
    lineHeight: "15px",
    [theme.breakpoints.up('sm')]: {
      fontSize: "1.2em",
      lineHeight: "20px",
      paddingLeft: 220,
      maxWidth: 280
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "0.8em",
      paddingLeft: "40%",
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "16px",
      maxWidth: 400
    },
  },
}));