import React, { useReducer } from 'react';
import ReactDOM from 'react-dom';
import { Lil, Introspecter } from '@loginline/odoo-frontend';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from "react-router-dom";
import theme from './Styles/ThemeStyles';
import "./index.css"
import * as serviceWorker from './serviceWorker';

import App from './App';
const initialState = {
    names: [],
};

const LilReact = () => {
    const [store, dispatch] = useReducer(Lil.LilReducer, initialState, Lil.LilInitState);
    return (
        <ThemeProvider theme={theme}>
            <Lil.LilContext.Provider value={[store, dispatch]}>
                <Router>
                    <App />
                    {process.env.NODE_ENV === "development" && <Introspecter />}
                </Router>
            </Lil.LilContext.Provider>
        </ThemeProvider>
    )
};


ReactDOM.render(<LilReact />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
