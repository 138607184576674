import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Slide } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Lil } from '@loginline/odoo-frontend'
import dataThemes from '../dataThemes.json';
import logoRoueBioCentre from '../Assets/logos/logo-roue-biodiversite-centre.png';
import bubbleHat from '../Assets/bulles/bulle-chapeau.png';
import bubbleShells from '../Assets/bulles/bulle-coquilles.png';
import bubbleScales from '../Assets/bulles/bulle-ecailles.png';
import bubbleMicro from '../Assets/bulles/bulle-micro.png';
import bubbleSkin from '../Assets/bulles/bulle-peau-lisse.png';
import bubblePaws from '../Assets/bulles/bulle-plein-pattes.png';
import bubbleFeathers from '../Assets/bulles/bulle-plumes.png';
import bubbleHair from '../Assets/bulles/bulle-poils.png';
import bubbleRoots from '../Assets/bulles/bulle-racines.png';
import bubbleKnow from '../Assets/bulles/bulle-savoir.png';

const bubble = {
  'bulle-chapeau.png': bubbleHat,
  'bulle-coquilles.png': bubbleShells,
  'bulle-ecailles.png': bubbleScales,
  'bulle-micro.png': bubbleMicro,
  'bulle-peau-lisse.png': bubbleSkin,
  'bulle-plein-pattes.png': bubblePaws,
  'bulle-plumes.png': bubbleFeathers,
  'bulle-poils.png': bubbleHair,
  'bulle-racines.png': bubbleRoots,
  'bulle-savoir.png': bubbleKnow,
}

export default function HeaderQuiz() {
  const router = useHistory();
  const classes = useStyles();
  const [store,] = Lil.useStore();
  const currentThemeBubble = store.category ?
    (
      (dataThemes.filter(theme => theme.name === store.category))[0].bubble
    )
    :
    (
      "bulle-savoir.png"
    )

  return (
    <Box >
      <Grid className={classes.header}>
        <img onClick={() => { router.push(`/home`) }} className={classes.logo}
          src={logoRoueBioCentre} alt="logo roue biodiversite" />
        <Slide direction="left" in={true} mountOnEnter>
          <Grid className={classes.imgWrap}>
            <img className={classes.img} src={bubble[currentThemeBubble]} alt="Current theme" />
          </Grid>
        </Slide>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    padding: "20px 30px 20px 30px",
    display: "flex",
    justifyContent: "space-between",
    maxHeight: 66,
    [theme.breakpoints.up('sm')]: {
      padding: "30px 150px 30px 150px"
    },
  },
  logo: {
    maxWidth: "40%",
    [theme.breakpoints.up('md')]: {
      maxWidth: 210
    },
  },
  imgWrap: {
    width: "30%",
    textAlign: "center",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));