import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lil } from '@loginline/odoo-frontend'
import { useHistory } from 'react-router-dom';
import txtHat from '../Assets/textures/chapeau.png';
import txtShells from '../Assets/textures/coquilles.png';
import txtScales from '../Assets/textures/ecailles.png';
import txtMicro from '../Assets/textures/microscopique.png';
import txtSkin from '../Assets/textures/peau-lisse.png';
import txtPaws from '../Assets/textures/pattes.png';
import txtFeathers from '../Assets/textures/plumes.png';
import txtHair from '../Assets/textures/poils.png';
import txtRoots from '../Assets/textures/racines.png';
import txtKnow from '../Assets/textures/savoir.png';

const textures = {
  'chapeau.png': txtHat,
  'coquilles.png': txtShells,
  'ecailles.png': txtScales,
  'microscopique.png': txtMicro,
  'peau-lisse.png': txtSkin,
  'pattes.png': txtPaws,
  'plumes.png': txtFeathers,
  'poils.png': txtHair,
  'racines.png': txtRoots,
  'savoir.png': txtKnow,
}

export default function ThematicsItem(props) {

  return (
    <Grid style={{ margin: "10px 0 30px 0", textAlign: "center" }}>
      {
        props.thematics.map(themeProps =>
          <Composant themeProps={themeProps} key={themeProps.name} />
        )
      }
    </Grid>
  )
}

function Composant(props) {
  const classes = useStyles(props.themeProps);
  const [, update] = Lil.useStore();
  const router = useHistory();

  const changeVue = () => {
    router.push(`/app/tree`);
  }

  return (
    <Button className={classes.themeCSS}
      onClick={() => {
        update({ category: props.themeProps.name });
        changeVue();
      }}>
      <Typography variant="h4">{props.themeProps.name}</Typography>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({

  themeCSS: props => ({
    backgroundColor: theme.palette[props.color].main,
    backgroundImage: `url(${textures[props.bgUrl]})`,
    backgroundSize: "cover",
    width: "43%",
    height: 65,
    margin: 10,
    borderRadius: "15px",
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 280,
      margin: '20px 10px',
    },
  }),
}));