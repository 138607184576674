import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Components/Header';
import logoGraineNA from '../Assets/logos/logo_Graine_NA.png';
import logoNA from '../Assets/logos/logo_NA.png';
import logoUE from '../Assets/logos/logo_UE.jpeg';
import logoLIL from '../Assets/logos/logo-loginline-horizontal.png';

export default function Legals() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Box>

        <Grid className={classes.titleWrap}>
          <Typography className={classes.title} variant="h2">Mentions légales</Typography>
        </Grid>

        <Grid className={classes.subTitleWrap}>
          <Typography className={classes.subTitle} variant="h2">Rédaction</Typography>
        </Grid>

        <Grid className={classes.mentionsWrap}>
          <Grid>
            <img src={logoGraineNA} alt="logo graine aquitaine" className={classes.logo} />
          </Grid>
          <Grid>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions} variant="h5">
                Statut juridique :
              </Typography>
              <Typography className={classes.instructions}>
                Association loi 1901
              </Typography>
            </Grid>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions} variant="h5">
                Direction de Publication :
          </Typography>
              <Typography className={classes.instructions}>
                Les Co Présidents de l’Association GRAINE Nouvelle-Aquitaine
          </Typography>
            </Grid>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions} variant="h5">
                Coordonnées :
              </Typography>
              <Typography className={classes.instructions}>
                8, rue de l’Abbé Gaillard
                33830 Belin Beliet
              </Typography>
              <Typography className={classes.instructions}>
                infos@graine-nouvelle-aquitaine.fr
              </Typography>
              <Typography className={classes.instructions}>
                tél : 05 56 88 19 07
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.subTitleWrap}>
          <Typography className={classes.subTitle} variant="h2">Hébergement / Développement</Typography>
        </Grid>

        <Grid className={classes.mentionsWrap}>
          <Grid>
            <img className={classes.logoNAUE} src={logoLIL} alt="Log'in Line logo" />
          </Grid>
          <Grid className={classes.wrapGridTypo}>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions} variant="h5">
                Log'in Line SAS :
            </Typography>
            </Grid>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions}>
                37 Rue Colonel Grandier-Vazeille, 33000 Bordeaux
            </Typography>
            </Grid>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions}>
                <a href="http://www.loginline.com/" target="_blank" rel="noopener noreferrer">www.loginline.com</a>
              </Typography>
            </Grid>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions}>
                contact@loginline.com
            </Typography>
            </Grid>
            <Grid className={classes.gridTypo}>
              <Typography className={classes.instructions}>
                09 72 12 43 30
            </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.subTitleWrap}>
          <Typography className={classes.subTitle} variant="h2">Programme régional soutenu par</Typography>
        </Grid>

        <Grid className={classes.mentionsWrap}>
          <Grid>
            <img className={classes.logoNAUE} src={logoNA} alt="Nouvelle Aquitaine logo" />
          </Grid>
          <Grid className={classes.gridTypo}>
            <Typography className={classes.instructions} variant="h5">
              Région Nouvelle-Aquitaine :
              </Typography>
            <Typography className={classes.instructions}>
              14 rue François de Sourdis
              33077 BORDEAUX
              </Typography>
          </Grid>
        </Grid>

        <Grid className={classes.mentionsWrap}>
          <Grid>
            <img className={classes.logoNAUE} src={logoUE} alt="European Union logo" />
          </Grid>
          <Grid className={classes.gridTypo}>
            <Typography className={classes.instructions} variant="h5">
              FEDER :
              </Typography>
            <Typography className={classes.instructions}>
              Fonds européen de développement régional
              </Typography>
          </Grid>
        </Grid>

        <Grid className={classes.subTitleWrap}>
          <Typography className={classes.subTitle} variant="h2">Traitement des données personnelles</Typography>
        </Grid>

        <Grid>
          <Typography className={classes.personalData}>
            Lors d’une prise de contact avec GRAINE Nouvelle-Aquitaine (inscription, demande d’information, etc…),
            ou lors d’une inscription via les formulaires de contact, des données à caractère personnel
            sont collectées telles que le nom, le prénom, l'adresse email, etc.
            Ces données sont exclusivement réservées à l’usage de GRAINE Nouvelle-Aquitaine en vue de communiquer
            avec ses « usagers » sur les points administratifs, logistiques et pédagogiques
            qui les concernent directement, ainsi que de leur diffuser une information sur les parutions,
            formations à venir ou événements de GRAINE Nouvelle-Aquitaine qui pourraient les intéresser.

            GRAINE Nouvelle-Aquitaine ne vend ni ne cède ses données à aucun organisme, ni à but lucratif,
            ni même sans but lucratif.
            Pour faire valoir vos droits vis-à-vis de la gestion de vos données personnelles
            (droit à l’accès aux données, à leur portabilité (transfert),
            à la rectification et à l’effacement), envoyez une demande à rgpd@graine-nouvelle-aquitaine.fr.
            Nous nous engageons à prendre en compte toute demande de désinscription à nos informations
            ou d’effacement de vos données personnelles dans notre base sous 30 jours au maximum.

            En cas de non-respect de vos droits, vous pouvez contacter la Cnil.
              </Typography>
        </Grid>

      </Box>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  titleWrap: {
    display: "flex",
    justifyContent: "center"
  },
  title: {
    textAlign: "center",
    paddingBottom: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      paddingBottom: 30
    },
  },
  subTitleWrap: {
    display: "flex",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up('md')]: {
      justifyContent: "left",
    },
  },
  subTitle: {
    textAlign: "center"
  },
  mentionsWrap: {
    textAlign: "center",
    marginTop: 20,
    padding: 10,
    [theme.breakpoints.up('md')]: {
      display: "flex",
      // justifyContent: "center",
    },
  },
  logo: {
    maxHeight: 110,
    margin: "0 40px 0 20px",
    [theme.breakpoints.up('sm')]: {
      maxHeight: 160,
    },
  },
  logoNAUE: {
    maxHeight: 90,
    [theme.breakpoints.up('sm')]: {
      maxHeight: 120,
    },
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
  },
  wrapGridTypo: {
    display: 'bloc',
  },
  gridTypo: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },

  },
  instructions: {
    paddingLeft: 5,
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 20,
    },
  },
  personalData: {
    padding: 20,
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 20,
    },
  }
}));