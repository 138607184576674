import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Button, Box, Typography, Grid } from '@material-ui/core';
import { Lil } from '@loginline/odoo-frontend'

export default function Score() {
  const classes = useStyles();
  const router = useHistory();

  const [store,] = Lil.useStore();
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const sum = store.counter.reduce(reducer, 0);
  const score = Math.round((sum / store.counter.length) * 100);

  return (
    <Box>
      <Grid className={classes.grid}>
        <Typography className={classes.score}>
          {score}
        </Typography>
        <Typography className={classes.percent} display="block">
          %
      </Typography>
      </Grid>
      <Grid>
        <Typography variant="h4" className={classes.text}>
          De vos réponses sont bonnes
        </Typography>
      </Grid>
      <Grid className={classes.btn}>
        <Grid>
          <Button className={classes.homeBtn} onClick={() => { router.push(`/home`) }}>
            <Typography variant="h5">
              Revenir à l'accueil
            </Typography>
          </Button>
        </Grid>
        <Grid>
          <Button className={classes.biodivBtn} onClick={() => { router.push(`/biodiversity`) }}>
            <Typography variant="h5">
              La biodiversité chez vous
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  grid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 455px)',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 80
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    },
  },
  score: {
    fontSize: 137,
    [theme.breakpoints.up('sm')]: {
      fontSize: 158,
    },
    [theme.breakpoints.up('md')]: {

    },
  },
  percent: {
    fontSize: 35,
    paddingTop: 110
  },
  text: {
    fontSize: 36,
    textAlign: "center",
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      margin: "0 250px 0 250px"
    },
  },
  btn: {
    textAlign: "center",
    [theme.breakpoints.up('sm')]: {
      display: "flex",
      justifyContent: "center",
      marginTop: 80
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    },
  },
  homeBtn: {
    width: 320,
    margin: "40px 0 20px 0",
    height: 64,
    background: "transparent linear-gradient(180deg, #2AA7BC 0%, #14606E 100%) 0% 0% no-repeat padding-box",
    [theme.breakpoints.up('sm')]: {
      margin: "80px 10px 20px 10px",
      height: 82,
    },
  },
  biodivBtn: {
    width: 320,
    marginBottom: 40,
    height: 64,
    background: "transparent linear-gradient(180deg, #78B41D 0%, #59831B 100%) 0% 0% no-repeat padding-box",
    [theme.breakpoints.up('sm')]: {
      margin: "80px 10px 20px 10px",
      height: 82,
    },
  }
}));