import React, {useContext}  from "react";

const LilContext = React.createContext();

const Lil = global.Lil = {
    Account: {},
    LilContext,
    Store: {},
    LilReducer: (state, merged) => {
        Lil.Store = {...state, ...merged};
        window.sessionStorage.store = JSON.stringify(Lil.Store);
        return Lil.Store;
    },
    LilInitState: (state) => {
        const initState = window.sessionStorage.store ? JSON.parse(window.sessionStorage.store) : state;
        if(!Lil.InitialStore) Lil.InitialStore = state;
        Lil.Store = initState;
        window.sessionStorage.store = JSON.stringify(initState);
        return initState;
    },
    useStore: () => {
        return useContext(LilContext);
    },
    cleanStore: () => {
        Lil.Store = {};
        delete window.sessionStorage.store;
        return Lil.InitialStore;
    }
};

Lil.Account.login = async (user, password, callback = (result) => console.log("Logged in!", result)) => {
    const response = await fetch('/api/users/authenticate',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username: user, password: password})
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    Lil.Account.user = body;
    window.sessionStorage.authToken = JSON.stringify(body);
    callback(body);
};

Lil.Account.logout = () => {
    delete Lil.Account.user;
    delete window.sessionStorage.authToken;
};

Lil.method = async (methodName, params = {}, callback = (result) => console.log("Call Result: ", result)) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    if(Lil.Account.user) headers.Authorization = `Bearer ${Lil.Account.user.token}`
    const response = await fetch(`/api/methods/${methodName}`,{
        method: 'POST',
        headers,
        body: JSON.stringify(params)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    callback(body);
};

if(window.sessionStorage.authToken){
    Lil.Account.user = JSON.parse(window.sessionStorage.authToken);
}

export default Lil;