import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BiodiversityItem from '../Components/BiodiversityItem';
import dataThemes from '../dataThemes.json';
import Header from '../Components/Header';
import FooterBiodiversity from '../Components/FooterBiodiversity';

export default function Biodiversity() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Box className={classes.biodiversityContainer}>

        <Grid className={classes.titleWrap}>
          <Typography className={classes.title} variant="h3">La biodiversité chez vous</Typography>
        </Grid>

        <Grid className={classes.titleWrap}>
          <Typography className={classes.instructions} variant="h5">
            Choisissez votre thème favoris et découvrez des astuces et actions simples à réaliser
          dans votre quotidien afin d’aider la biodiversité !</Typography>
        </Grid>

        <Box>
          <BiodiversityItem thematics={dataThemes} />
        </Box>

      </Box>
      <FooterBiodiversity/>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  biodiversityContainer: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20%'
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '10%'
    },
  },
  titleWrap: {
    display: "flex",
    justifyContent: "center"
  },
  title: {
    maxWidth: "70%",
    textAlign: "center",
    paddingBottom: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      paddingBottom: 30
    },
  },
  instructions: {
    maxWidth: "90%",
    fontSize: 18,
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      maxWidth: "50%",
    },
  }
}));