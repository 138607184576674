import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Lil } from '@loginline/odoo-frontend'
import dataThemes from '../dataThemes.json';
import dataQuiz from '../dataQuiz.json';
import blueTree from '../Assets/arbres/arbre-bleu-clair.png';
import darkBlueTree from '../Assets/arbres/arbre-bleu-fonce.png';
import greyTree from '../Assets/arbres/arbre-gris.png';
import yellowTree from '../Assets/arbres/arbre-jaune.png';
import brownTree from '../Assets/arbres/arbre-marron.png';
import orangeTree from '../Assets/arbres/arbre-orange.png';
import redTree from '../Assets/arbres/arbre-rouge.png';
import greenTree from '../Assets/arbres/arbre-vert.png';
import appleGreenTree from '../Assets/arbres/arbre-vert-pomme.png';
import purpleTree from '../Assets/arbres/arbre-violet.png';
import bubbleHat from '../Assets/bulles/bulle-chapeau.png';
import bubbleShells from '../Assets/bulles/bulle-coquilles.png';
import bubbleScales from '../Assets/bulles/bulle-ecailles.png';
import bubbleMicro from '../Assets/bulles/bulle-micro.png';
import bubbleSkin from '../Assets/bulles/bulle-peau-lisse.png';
import bubblePaws from '../Assets/bulles/bulle-plein-pattes.png';
import bubbleFeathers from '../Assets/bulles/bulle-plumes.png';
import bubbleHair from '../Assets/bulles/bulle-poils.png';
import bubbleRoots from '../Assets/bulles/bulle-racines.png';
import bubbleKnow from '../Assets/bulles/bulle-savoir.png';

const tree = {
    'arbre-bleu-clair.png': blueTree,
    'arbre-gris.png': greyTree,
    'arbre-yellow.png': yellowTree,
    'arbre-marron.png': brownTree,
    'arbre-orange.png': orangeTree,
    'arbre-bleu-fonce.png': darkBlueTree,
    'arbre-rouge.png': redTree,
    'arbre-vert.png': greenTree,
    'arbre-vert-pomme.png': appleGreenTree,
    'arbre-violet.png': purpleTree,

}

const bubble = {
    'bulle-chapeau.png': bubbleHat,
    'bulle-coquilles.png': bubbleShells,
    'bulle-ecailles.png': bubbleScales,
    'bulle-micro.png': bubbleMicro,
    'bulle-peau-lisse.png': bubbleSkin,
    'bulle-plein-pattes.png': bubblePaws,
    'bulle-plumes.png': bubbleFeathers,
    'bulle-poils.png': bubbleHair,
    'bulle-racines.png': bubbleRoots,
    'bulle-savoir.png': bubbleKnow,
}

export default function Tree() {
    const classes = useStyles();
    const router = useHistory();
    const [store, update] = Lil.useStore();
    const currentThemeTree = store.category ?
        ((dataThemes.filter(theme => theme.name === store.category))[0].tree) : ("arbre-violet.png")
    const currentThemeBubble = store.category ? ((dataThemes.filter(theme => theme.name === store.category))[0].bubble) : ("bulle-avoir.png")

    useEffect(() => {
        const categoryArray = dataQuiz.filter(({ category }) => category === store.category)
        update({ questionsArrayUpdated: categoryArray });
        setTimeout(function () { router.push(`/quiz`) }, 2000);
    }, [router, store.category, update]);

    return (
        <Box className={classes.tree}>
            <Box style={{ position: 'inherit' }}>
                <img src={tree[currentThemeTree]} alt="Tree" className={classes.pictureTree} />
                <img src={bubble[currentThemeBubble]} alt="Current theme bubble" className={classes.pictureBubble} />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    tree: {
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            minHeight: 'calc(100vh - 100px)',
        },
    },
    pictureTree: {
        position: 'fixed',
        width: '110%',
        height: '80%',
        right: -50,
        // // Ipad 
        [theme.breakpoints.up('sm')]: {
            width: '65%',
            height: '70%',
            right: '15%',
            bottom: '10%'
        },
        [theme.breakpoints.up('md')]: {
            width: '40%',
            height: '75%',
            right: '30%',
            bottom: '10%'
        },
        // Desktop
        [theme.breakpoints.up('lg')]: {
            width: '30%',
            height: '80%',
            right: '35%',
            bottom: '10%'
        },
    },
    pictureBubble: {
        position: 'inherit',
        width: '70%',
        marginTop: '35%',
        marginLeft: '15%',
        // Ipad
        [theme.breakpoints.up('sm')]: {
            position: 'fixed',
            width: '35%',
            marginTop: '0%',
            marginLeft: '0%',
            left: '30%',
            bottom: '50%'
        },
        [theme.breakpoints.up('md')]: {
            width: '22%',
            marginLeft: '5%'
        },
    }
}));
