import React, { useState } from 'react';
import { Button, Grid, Typography, Box, Paper, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import arrowDownWhite from '../Assets/icones/fleche-bas-blanche-graine.png';
import txtHat from '../Assets/textures/chapeau.png';
import txtShells from '../Assets/textures/coquilles.png';
import txtScales from '../Assets/textures/ecailles.png';
import txtMicro from '../Assets/textures/microscopique.png';
import txtSkin from '../Assets/textures/peau-lisse.png';
import txtPaws from '../Assets/textures/pattes.png';
import txtFeathers from '../Assets/textures/plumes.png';
import txtHair from '../Assets/textures/poils.png';
import txtRoots from '../Assets/textures/racines.png';
import txtKnow from '../Assets/textures/savoir.png';

const textures = {
  'chapeau.png': txtHat,
  'coquilles.png': txtShells,
  'ecailles.png': txtScales,
  'microscopique.png': txtMicro,
  'peau-lisse.png': txtSkin,
  'pattes.png': txtPaws,
  'plumes.png': txtFeathers,
  'poils.png': txtHair,
  'racines.png': txtRoots,
  'savoir.png': txtKnow,
}

export default function BiodiversityItem(props) {

  return (
    <Grid style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '50px',
    }} >
      {
        props.thematics.map(themeProps =>
          <Composant themeProps={themeProps} key={themeProps.name} />
        )
      }
    </Grid>
  )
}

function Composant(props) {
  const [detailIsOpen, setDetailIsOpen] = useState(false);
  const classes = useStyles(props.themeProps);

  return (
    <>
      <Button className={classes.themeCSS} onClick={() => setDetailIsOpen(!detailIsOpen)}>
        <Box className={classes.chevronBox}>
          <img className={classes.chevron} src={arrowDownWhite} alt="arrow down" />
        </Box>
        <Typography variant="h4">
          {props.themeProps.name}
        </Typography>
        <Box className={classes.chevronBox}>
          <img className={classes.chevron} src={arrowDownWhite} alt="arrow down" />
        </Box>
      </Button>

      <Collapse in={detailIsOpen} className={classes.detailContainer}>
        <Paper elevation={0} className={classes.detail}>
          {/* title1 */}
          <Typography>{props.themeProps.title1}</Typography>

          {props.themeProps.link11 !== "" &&
            <a className={classes.link} href={props.themeProps.link11} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text11}</Button>
            </a>
          }

          {props.themeProps.link12 !== "" &&
            <a className={classes.link} href={props.themeProps.link12} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text12}</Button>
            </a>
          }

          <Typography>{props.themeProps.text13}</Typography>

          {/* title2 */}
          <Typography>{props.themeProps.title2}</Typography>

          {props.themeProps.link21 !== "" &&
            <a className={classes.link} href={props.themeProps.link21} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text21}</Button>
            </a>
          }

          {props.themeProps.link22 !== "" &&
            <a className={classes.link} href={props.themeProps.link22} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text22}</Button>
            </a>
          }

          {props.themeProps.link23 !== "" &&
            <a className={classes.link} href={props.themeProps.link23} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text23}</Button>
            </a>
          }


          {/* title3 */}
          <Typography>{props.themeProps.title3}</Typography>

          {props.themeProps.link31 !== "" &&
            <a className={classes.link} href={props.themeProps.link31} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text31}</Button>
            </a>
          }

          {props.themeProps.link32 !== "" &&
            <a className={classes.link} href={props.themeProps.link32} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text32}</Button>
            </a>
          }



          {/* title5 */}
          <Typography>{props.themeProps.title5}</Typography>

          {props.themeProps.link51 !== "" &&
            <a className={classes.link} href={props.themeProps.link51} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text51}</Button>
            </a>
          }

          {/* title6 */}
          <Typography>{props.themeProps.title6}</Typography>

          {props.themeProps.link61 !== "" &&
            <a className={classes.link} href={props.themeProps.link61} target="_blank" rel="noopener noreferrer" >
              <Button className={classes.detailBtns}>{props.themeProps.text61}</Button>
            </a>
          }

        </Paper>
      </Collapse>
    </>
  )
}

const useStyles = makeStyles(theme => ({

  themeCSS: props => ({
    backgroundColor: `${theme.palette[props.color].main} !important`,
    backgroundImage: `url(${textures[props.bgUrl]})`,
    backgroundPosition: "-5px -30px",
    backgroundSize: "cover",
    justifyContent: "space-between",
    width: "300px",
    height: 60,
    marginTop: 40,
    marginBottom: -30,
    borderRadius: "15px",
    padding: "0 20px",
    [theme.breakpoints.up('sm')]: {
      width: 652,
      margin: "35px 0 -25px 0",
    },
    [theme.breakpoints.up('lg')]: {
      width: 760,
    },
  }),
  detailContainer: {
    display: "inline-block"
  },
  detail: props => ({
    backgroundColor: theme.palette[props.color].main,
    color: "white",
    textAlign: "center",
    width: "260px",
    padding: "40px 20px 20px 20px",
    borderRadius: "15px",
    [theme.breakpoints.up('sm')]: {
      width: "610px",
    },
    [theme.breakpoints.up('md')]: {
      width: "612px",
      padding: "40px 20px 20px 20px",
      textAlign: "center",
    },
    [theme.breakpoints.up('lg')]: {
      width: "720px",
      padding: "40px 20px 20px 20px",
      textAlign: "center",
    },
  }),
  chevronBox: {
    width: 30,
    textAlign: "center",
  },
  chevron: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  link: {
    textDecoration: "none",
  },
  detailBtns: props => ({
    backgroundColor: theme.palette[props.color].main,
    padding: 10,
    margin: "10px 20px",
  }),
}));
