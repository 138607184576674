import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TWEEN from '@tweenjs/tween.js';
import { makeStyles } from '@material-ui/core/styles';
import Needle from '../Assets/needle.png';
import RoueBiodiversite from '../Assets/roue_biodiversite.png';

const animate = time => {
    requestAnimationFrame(animate);
    TWEEN.update(time);
};

requestAnimationFrame(animate);
const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

const getAngleFromPick = pick => ((pick / 10) * 360 + 720 + (10 - getRandomInt(20)));

const Wheel = props => {
    const classes = useStyles();
    const [rotation, setRotation] = useState(-1);
    const [initialIndex,] = useState(props.initialIndex);

    useEffect(() => {
        if (rotation > 0 || props.categoryIndex >= 0) {
            const tweenProp = {
                rotation
            };
            const tween = new TWEEN.Tween(tweenProp)
                .to({ rotation: getAngleFromPick(props.categoryIndex) - (initialIndex / 10) * 360 }, 2000)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .onUpdate(() => {
                    setRotation(tweenProp.rotation);
                })
                .start();
            return () => {
                tween.stop();
            }
        }
    }, [props.categoryIndex]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img alt="needle" src={Needle} className={classes.needle} />
            <img alt="Roue" src={RoueBiodiversite} className={classes.wheel}
                style={{
                    transform: `rotate(${rotation + (initialIndex / 10) * 360}deg)`,
                    transformOrigin: 'center center',
                }}
            />
        </div>
    )
};

Wheel.propTypes = {
    categoryIndex: PropTypes.number
};
Wheel.defaultProps = {
    categoryIndex: -1
};

const useStyles = makeStyles(theme => ({
    needle: {
        zIndex: 1,
        width: 30,
        height: 60,
        [theme.breakpoints.up('sm')]: {
            width: 42,
            height: 78,
        },
        [theme.breakpoints.up('md')]: {
            width: 32,
            height: 58,
        },
        [theme.breakpoints.up('lg')]: {
            width: 42,
            height: 78,
        },
    },
    wheel: {
        width: 300,
        height: 300,
        marginTop: -35,
        [theme.breakpoints.up('sm')]: {
            width: 550,
            height: 550,
        },
        [theme.breakpoints.up('md')]: {
            width: 350,
            height: 350,
        },
        [theme.breakpoints.up('lg')]: {
            width: 400,
            height: 400,
        },
        [theme.breakpoints.up('xl')]: {
            width: 480,
            height: 480,
        },
    }
}));

export default Wheel;