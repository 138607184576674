import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#78B41D',
        },
        secondary: {
            main: '#49301D',
        },
        tertiary: {
            main: '#B6DDF2',
        },
        dark: {
            main: '#333745',
        },
        light: {
            main: '#FFFFFF',
        },
        asavoir: {
            main: '#881D82',
        },
        aracines: {
            main: '#EC256F',
        },
        apleindepattes: {
            main: '#4F413A',
        },
        ataillemicro: {
            main: '#00A577',
        },
        apeaulisse: {
            main: '#DC643A',
        },
        aecailles: {
            main: '#FBA940',
        },
        apoils: {
            main: '#95C11F',
        },
        aplumes: {
            main: '#239EDB',
        },
        acoquilles: {
            main: '#6A6E70',
        },
        achapeau: {
            main: '#282D83',
        },
    },
    // Définiton des propriétés de la police suivant la maquette
    typography: {

        fontFamily: 'Helvetica',

        body1: {
            fontSize: 16,
        },
        h1: {
            fontSize: 60,
            fontWeight: "bold",
            textAlign: "center",
        },
        h2: {
            fontSize: 34,
            fontWeight: "bold"
        },
        h3: {
            fontSize: 30,
            fontWeight: "bold"
        },
        h4: {
            fontSize: 22,
            fontFamily: "MilkShake",
        },
        h5: {
            fontSize: 22,
            fontWeight: "bold"
        },
        a: {
            textDecoration: "underline",
            color: "#BE4887"
        }
    },
    overrides: {
        MuiButton: {
            root: {
                backgroundColor: '#78B41D',
                color: 'white',
                "&:hover": { backgroundColor: "none" },
                width: "auto",
                height: "auto",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: "40px",
                boxShadow: "0px 0px 10px #0000004D",
                border: "1px solid white",
                opacity: 1,
                textTransform: "none",
            },
        },
        MuiInput: {
            root: {
                width: "80%",
                height: "60px",
                boxShadow: "0px 3px 10px #BE488727",
                borderRadius: "20px",
                paddingLeft: "4%",
                "&$focused": {
                    boxShadow: "4px 3px 10px 2px #BE488727",
                }
            },
        },
        MuiContainer: {
            root: {
                padding: "0px",
                marginLeft: "0px",
                marginRight: "0px",
                width: "100%"
            }
        },
        MuiMenu: {
            paper: {
                boxShadow: 'none',
                background: '#BE4887',
                padding: '24px'
            },
        },
    }
});

export default theme;