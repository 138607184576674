import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Typography } from '@material-ui/core';
import logoGraineNA from '../Assets/logos/logo_Graine_NA.png';
import logoNA from '../Assets/logos/logo_NA.png';
import logoUE from '../Assets/logos/logo_UE.jpeg';

export default function Footer() {
  const classes = useStyles();
  const router = useHistory();

  return (
    <Box className={classes.footer}>
      <Grid>

        <Grid className={classes.wrapGrid}>
          <Grid item xs={4} md={4}>
          </Grid>
          <Grid item xs={4} md={4}>
            <img className={classes.logoNA} src={logoNA} alt="Nouvelle Aquitaine logo" />
          </Grid>
          <Grid item xs={4} md={4}>
            <img className={classes.logoUE} src={logoUE} alt="European Union logo" />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} className={classes.row1}>
          <Grid item xs={4} md={4} className={classes.logoGraineContainer}>
            <Typography className={classes.propsed}>Proposé par</Typography>
            <a href="https://www.graine-aquitaine.org/" target="_blank" rel="noopener noreferrer"><img src={logoGraineNA} alt="logo graine aquitaine" className={classes.logo} /></a>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography className={classes.text}>
              Ce projet est cofinancé par l’Union européenne. L’Europe s’engage en Aquitaine avec le Fonds Européen de Développement Régional (FEDER)
        </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} className={classes.row2}>
          <Grid item xs={6} sm={12} md={12}>
            <Typography className={classes.link} onClick={() => { router.push(`/legals`) }}>
              Mentions légales
                        </Typography>
          </Grid>
          <Grid item xs={4} sm={12} md={12}>
            <Typography className={classes.linkCgu}>
              <a className={classes.link} href="https://www.graine-aquitaine.org/" target="_blank" rel="noopener noreferrer" > CGU </a>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={12} md={12} className={classes.proposedByGA}>
            <Typography>
              <a className={classes.link} href="https://www.graine-aquitaine.org/" target="_blank" rel="noopener noreferrer"> Proposé par Graine Aquitaine </a>
            </Typography>
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: "100%",
    // height: 65,
    // bottom: -30,
    backgroundColor: theme.palette.secondary.main,
    // Ipad
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      bottom: 0,
      height: 65,
    },
  },
  row1: {
    display: 'none',
    //maxHeight: 50,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      maxHeight: 50,
      //display: 'inherit',
      //position: 'inherit'
    },
  },
  row2: {
    display: 'flex',
    textAlign: 'center',
    marginTop: 10,
    // Ipad
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 120
    },
    // Desktop
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      marginTop: 0
    }
  },
  text: {
    color: 'lightgray',
    fontSize: 10,
    paddingTop: 5,
    [theme.breakpoints.up('sm')]: {
      marginTop: 5,
      width: 305,
      fontSize: 14,
      position: "absolute",
      left: "5%"
    },
    [theme.breakpoints.up('md')]: {
      width: "40%",
      maxWidth: 550,
      fontSize: "0.8em",
      left: "7%",
      marginTop: -5,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 480,
    },
  },
  logoGraineContainer: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: 235,
      right: "7%",
    },
  },
  propsed: {
    color: "lightgray",
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      position: "absolute",
      right: 135,
      marginTop: 30
    },
    [theme.breakpoints.up('md')]: {
      position: "absolute",
      marginTop: 9
    },
  },
  logo: {
    maxHeight: 110,
    marginTop: -60,
    marginLeft: 20,
    [theme.breakpoints.up('sm')]: {
      maxHeight: 140,
      position: "absolute",
      right: 35,
      marginTop: -65,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: -90,
    },
  },
  link: {
    textDecoration: "none",
    color: 'lightgray',
    fontSize: 14,
    cursor: "pointer",
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      width: 200,
    },
  },
  linkLegalMentions: {
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  linkCgu: {
    // Ipad
    [theme.breakpoints.up('sm')]: {
      marginTop: 8,
      marginBottom: 15
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 100
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 200
    },
  },
  wrapGrid: {
    display: 'none',
    // position: 'absolute',
    // display: 'flex',
    // bottom: 85,
    // Ipad
    [theme.breakpoints.up('sm')]: {
      display: 'inherit',
      position: 'inherit'
    },
  },
  logoNA: {
    width: "90%",
    [theme.breakpoints.up('sm')]: {
      maxWidth: 130,
      position: "absolute",
      left: "20%",
      bottom: 90
    },
    [theme.breakpoints.up('md')]: {
      left: "15%",
      bottom: 75
    },
  },
  logoUE: {
    width: "55%",
    [theme.breakpoints.up('sm')]: {
      maxWidth: 80,
      position: "absolute",
      left: "7%",
      bottom: 90
    },
    [theme.breakpoints.up('md')]: {
      bottom: 75
    },
  },
  proposedByGA: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  }
}));