import React, { useState } from "react";
import Question from '../Components/Question';
import Answer from '../Components/Answer';
import Score from '../Components/Score';
import { Lil } from '@loginline/odoo-frontend'
import dataThemes from '../dataThemes.json';
import HeaderQuiz from '../Components/HeaderQuiz';
import FooterHome from '../Components/Footer';
import WheelPlay from "./WheelPlay";

export default function Quiz() {
  const [displayQuestion, setDisplayQuestion] = useState(true);
  const [displayAnswer, setDisplayAnswer] = useState(false);
  const [displayScore, setDisplayScore] = useState(false);
  const [displayWheel, setDisplayWheel] = useState(false);

  const [store,] = Lil.useStore();
  const themeCorresp = dataThemes.filter(categ => categ.name === store.category);
  const color = themeCorresp[0].color;

  const changeVue = (page) => {
    switch (page) {
      case 'question':
        return (setDisplayQuestion(true), setDisplayAnswer(false), setDisplayScore(false), setDisplayWheel(false));
      case 'answer':
        return (setDisplayQuestion(false), setDisplayAnswer(true), setDisplayScore(false), setDisplayWheel(false));
      case 'score':
        return (setDisplayQuestion(false), setDisplayAnswer(false), setDisplayScore(true), setDisplayWheel(false));
      case 'wheel':
        return (setDisplayQuestion(false), setDisplayAnswer(false), setDisplayScore(false), setDisplayWheel(true));
      default:
        return (setDisplayQuestion(true), setDisplayAnswer(false), setDisplayScore(false), setDisplayWheel(false));
    }
  }

  return (
    <>
      <HeaderQuiz />
      {displayQuestion && (
        <Question color={color} changeVue={changeVue} />
      )}
      {displayAnswer && (
        <Answer changeVue={changeVue} />
      )}
      {displayScore && (
        <Score />
      )}
      {displayWheel && (
        <WheelPlay
          prevTheme={themeCorresp[0]}
        />
      )}
      <FooterHome />
    </>
  )
}