import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ThematicsItem from '../Components/ThematicsItem';
import dataThemes from '../dataThemes.json';

export default function Theme() {
  const classes = useStyles();

  return (
    <Box className={classes.thematicsContainer}>

      <Grid className={classes.titleWrap}>
        <Typography className={classes.title} variant="h3">Choissez votre thème pour jouer</Typography>
      </Grid>

      <ThematicsItem thematics={dataThemes} />

    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  thematicsContainer: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 175px)',
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '10%'
    },
    [theme.breakpoints.up('md')]: {
      padding: "0 100px"
    },
  },
  titleWrap: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.only('sm')]: {
      marginBottom: '10%'
    },
  },
  title: {
    maxWidth: "70%",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
      maxWidth: "30%",
      paddingBottom: 30
    },
  },
}));