import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Grid } from '@material-ui/core';
import { Lil } from '@loginline/odoo-frontend'

export default function Question(props) {
  const classes = useStyles(props);
  const [store, update] = Lil.useStore();
  // About the question
  const [randomQuestion, setRandomQuestion] = useState(null)
  // About the answers
  const [isAnwered, setIsAnwered] = useState(false);
  // About the selected answer
  const [isCorrect, setIsCorrect] = useState(true);
  const [idTrueAnswer, setIdTrueAnswer] = useState(null);
  const [checkedId, setCheckedId] = useState(null);
  const counterArray = store.counter || [];
  const updateQuestions = store.questionsArrayUpdated;

  const updateQuestionsArray = () => {
    setTimeout(function () {
      update({
        questionsArrayUpdated: updateQuestions.filter((infoOfCategory, index) =>
          index !== randomQuestion),
      });
    }, 3000)
  }

  // Set a random Question in the current category
  useEffect(() => {
    updateQuestions &&
      setRandomQuestion(Math.floor(Math.random() * Math.floor(updateQuestions.length - 1)));
  }, [updateQuestions]);

  // Check if answer is true or false and save the points and set CheckedId (function called onClick)
  const checkAnswer = (id) => {
    setCheckedId(updateQuestions[randomQuestion].correctAnswer)
    if (id === updateQuestions[randomQuestion].correctAnswer) {
      setIsCorrect(true);
      counterArray.push(1);
      update({ counter: counterArray });
      setIdTrueAnswer(updateQuestions[randomQuestion].correctAnswer);
      setIsAnwered(true);
    }
    else {
      setIsCorrect(false);
      counterArray.push(0);
      update({ counter: counterArray });
      setIdTrueAnswer(updateQuestions[randomQuestion].correctAnswer);
      setIsAnwered(true);
    }
  }

  return (

    <Box className={classes.container}>
      <Grid className={classes.wrapQuestion}>
        {
          updateQuestions &&
          updateQuestions.length > 0 &&
          (
            isAnwered ? (
              isCorrect ? (
                <Box className={classes.trueOrFalse}> Correct !</Box>
              ) : (
                  <Box className={classes.trueOrFalse}> Dommage !</Box>
                )
            ) : (
                <Typography variant="h5" className={classes.question}>
                  {
                    randomQuestion !== null && updateQuestions[randomQuestion].question
                  }
                </Typography>
              )
          )
        }
      </Grid>
      <Grid className={classes.choicesBtns}>
        {
          updateQuestions &&
            updateQuestions.length > 0 ? (
              randomQuestion !== null &&
              updateQuestions[randomQuestion].answers.map((answer, id) =>
                <Button className={classes.choiceBtn} disabled={isAnwered}
                  style={{ color: isAnwered && "white", opacity: (checkedId !== id && checkedId !== null) && "0.2", backgroundColor: (idTrueAnswer === id) && '#78B41D' }}
                  onClick={() => {
                    setIsAnwered(!isAnwered);
                    checkAnswer(id);
                    update({
                      question: updateQuestions[randomQuestion],
                    });
                    updateQuestionsArray();
                    setTimeout(function () { props.changeVue("answer"); }, 3000)
                  }}
                  key={id}>
                  {answer}
                </Button>
              )
            )
            :
            (setTimeout(function () { props.changeVue("score") }, 100))
        }
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 175px)',
    },
  },
  wrapQuestion: {
    [theme.breakpoints.up('sm')]: {
     height: 305
    },
    [theme.breakpoints.up('md')]: {
      height: 100
    },
  },
  trueOrFalse: {
    fontFamily: "Milkshake",
    fontSize: "4em"
  },
  question: {
    width: "90%",
    display: "inline-block",
    lineHeight: "25px",
    [theme.breakpoints.up('sm')]: {
      lineHeight: "35px",
      fontSize: 35,
      padding: "100px 20px 100px 20px",
    },
    [theme.breakpoints.up('md')]: {
      //width: "50%",
      paddingTop: 0,
      paddingBottom: 0
    },
    [theme.breakpoints.up('xl')]: {
      width: "50%",
      paddingTop: 30,
      paddingBottom: 30
    },
  },
  choicesBtns: {
    display: "inline-block",
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 30,
      maxWidth: 1000,
    },
  },
  choiceBtn: props => ({
    transition: "1s",
    backgroundColor: theme.palette[props.color].main,
    width: "80%",
    height: 80,
    margin: 10,
    borderRadius: "15px",
    padding: 0,
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      width: 330,
      height: 130,
      margin: '20px 10px',
    },
    [theme.breakpoints.up('md')]: {
      width: 444,
      height: 130,
      margin: '20px 10px',
    },
  }),
}));
